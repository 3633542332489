import React from 'react';
import { graphql } from 'gatsby';
import { HelmetDatoCms } from 'gatsby-source-datocms';
import MainContent from '../components/mainContent';
import Jumbo from '../components/jumbo';
import Layout from '../components/Layout';

const WellnessPage = ({ data }) => (
  <Layout>
    <HelmetDatoCms seo={data.datoCmsWellbeingPage.seoMetaTags} />
    <Jumbo
      image={data.datoCmsWellbeingPage.headerImage.fluid}
      title={data.datoCmsWellbeingPage.mainHeading}
      subTitle={data.datoCmsWellbeingPage.subHeading}
    />

    <MainContent content={data.datoCmsWellbeingPage.mainText} />
  </Layout>
);

export default WellnessPage;

export const query = graphql`
  query WellnessPageQuery {
    datoCmsWellbeingPage {
      mainHeading
      subHeading
      mainText
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      headerImage {
        url
        fluid(maxWidth: 600, imgixParams: { fm: "jpg", auto: "compress" }) {
          ...GatsbyDatoCmsFluid
        }
      }
    }
  }
`;
